export const environment = {
  production: true,
  baseUrl: 'https://prod-backendop.mottu.dev',
  baseUrlMonitor: 'https://beta-backend-monitor.mottu.cloud/',
  monitorFieldServicesApi: "https://monitor-field-service.mottu.cloud/",
  baseUrlIotLock: 'https://iot-lock.mottu.cloud/',
  baseUrlInventario: "https://monitor-inventory.mottu.cloud/",
  signalRHub: 'https://monitorsuporterecolhimento.azurewebsites.net/api/',
  googleKey: 'AIzaSyDynpesuBknu-hxMFSFNWvvkbT-QzAHCHY',
  baseUrlAdminMottu: 'https://admin.mottu.cloud/',
  baseUrlAdminMottuV3: "https://operacao.mottu.cloud",
  baseUrlAlerta:'https://monitor-alerts-service.mottu.cloud',
  baseUrlQuestioner : "https://monitor-questioner.mottu.cloud/",
  pipefyApiUrl: "https://api.pipefy.com/graphql",
  baseUrlMonitorIntegrator: "https://monitor-integrator-manager.mottu.cloud/",
  baseUrlPagtoService:"https://monitor-pagamento.mottu.cloud/",
  mottuMotos: "https://motos.mottu.cloud/",
  baseUrlMaintenanceTriage: "https://maintenance-triage.mottu.io/",
  baseUrlMottueBackend: "https://mottue-backend.mottu.cloud/",
  baseUrlOcorrenciaService: "https://monitor-ocorrencias.mottu.cloud/",
  baseUrlApreensoesService: "https://monitor-apreensoes.mottu.cloud/",
  pipefyApiKey: "eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJQaXBlZnkiLCJpYXQiOjE3MjIzNDg5MTksImp0aSI6IjgyODY3YzczLTY1NzEtNDhmNi04ZmY5LTEyOGJjYWMzM2ZhNyIsInN1YiI6MzAyNTgyMDgwLCJ1c2VyIjp7ImlkIjozMDI1ODIwODAsImVtYWlsIjoicmVuYXRhLmZlcnJlaXJhQG1vdHR1LmNvbS5iciJ9fQ.kYzXLvrtQPvhO6pSlLpkuJAmLbJY9lqPczcB3rOYXOOyU8gkkzOOdSaZ5htC7yCQRHRji3xIUUguX9SF1N82lQ",
  monitorIntegratorToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiJTSU5JU1RSTy1QSVBFRlktYmRiZWFhYmJhMjZjZDA1MTE5MjMiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoie1wiQ2xpZW50SWRcIjpcIlNJTklTVFJPLVBJUEVGWS1iZGJlYWFiYmEyNmNkMDUxMTkyM1wiLFwiU2FsdFwiOlwiY2IxNmQ1MTgtMDk5MC00MmFiLTg0NTItZjk2NWU5NmRlZGRmXCJ9IiwibmJmIjoxNjkyOTkyNDQ4LCJleHAiOjIxMzQ3NTU2NDcsImlhdCI6MTY5Mjk5MjQ0OH0.7-speS7zVCUcxVTOnF1zW4oEhjVEaT1LWMkdKLaBrsU",
  branchManagementApi: "https://branch-management.mottu.cloud/",
  baseUrlBackendMaintenance: "https://maintenance-backend.mottu.cloud/",
  baseUrlMaintenance: "https://maintenance.mottu.cloud/",
  baseUrlGatewayAlert: "https://gateway-alerts.mottu.cloud/",
  baseUrlOperation: "https://operation-backend.mottu.cloud/",
  baseUrlOperationBackendLegacy: "https://operation-backend.mottu.cloud/",
  baseUrlPhotoBooth: "https://maintenance-photo-booth.mottu.cloud/",
  baseUrlBranch: "https://branch-management.mottu.cloud/",
  baseUrlMessageIntegratorUser: "https://message-integration.mottu.cloud/",
  intercomUrl: "https://api-iam.intercom.io",
  baseUrlCustomerSuccessV2: "https://customer-success-v2.mottu.cloud",
  baseUrlOperationBackend: "https://operacao.mottu.cloud",
  baseUrlCustomerSuccess: "https://customer-success.mottu.cloud",
  muralAvisosLink: "https://developer-71117.bubbleapps.io",
  baseUrlEmployeeManagement: "https://employee-management.mottu.cloud/",
  intercomId: "j7qzgu9w",
  keycloak: {
    url: "https://sso.mottu.cloud",
    realm: "Internal",
    clientId: "fleet-monitor-frontend-client",
  },
};
